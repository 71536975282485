import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Room } from '../utils/interfaces';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  Modal,
  Backdrop,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { deleteKeyStore } from '../utils/indexedDB';
import ErrorMessages from '../helper/ErrorMessages';
import { useUserStore } from '../stores/UserStore';
import { useRoomStore } from '../stores/RoomStore';

function PropertyDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loadUserFromIndexedDB } = useUserStore();
  const { rooms, loadRoomsFromIndexedDB } = useRoomStore();
  const errorMessages = new ErrorMessages();
  const property = location.state?.property;
  const [propertyEditable, setPropertyEditable] = useState(false);
  const [roomEditable, setRoomEditable] = useState(false);
  const [editedProperty, setEditedProperty] = useState(property);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newRoomCount, setNewRoomCount] = useState(0);
  const currencySymbol = localStorage.getItem('Currency') || '$'; // Retrieve currency symbol

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await loadUserFromIndexedDB();
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchUser();
  }, [loadUserFromIndexedDB]);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        await loadRoomsFromIndexedDB(property.PropertyID);
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchRooms();
  }, [loadRoomsFromIndexedDB]);

  // Handle delete property operation
  const handleDelete = async () => {
    try {
      // Delete the property from the server
      await api.deleteProperty(property.PropertyID);

      // Remove the property from IndexedDB
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${property.PropertyID}`);

      toast.success(t('delete_success'));
      navigate('/property');
    } catch (error) {
      const errorMessage = errorMessages.getErrorMessage(
        // @ts-ignore
        error.statusCode,
        // @ts-ignore
        error.context
      );

      toast.error(errorMessage);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  // Handle update property operation
  const handleUpdate = async () => {
    try {
      // Update the property on the server
      await api.updateProperty(editedProperty.PropertyID, editedProperty);

      // Remove the old entry from IndexedDB
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${property.PropertyID}`);

      toast.success(t('updated'));
      navigate('/property');
    } catch (error) {
      const errorMessage = errorMessages.getErrorMessage(
        // @ts-ignore
        error.statusCode,
        // @ts-ignore
        error.context
      );

      toast.error(errorMessage);
    } finally {
      setPropertyEditable(false);
    }
  };

  // Handle cancel button click
  const handleCancel = () => {
    if (propertyEditable) {
      setPropertyEditable(false);
      setEditedProperty(property);
    } else {
      navigate('/property');
    }
  };

  // Enter edit mode for property
  const handleEditProperty = () => {
    setPropertyEditable(true);
  };

  // Handle change in room count input
  const handleRoomCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(e.target.value, 10); // Parse input value as integer
    if (!isNaN(count)) {
      setNewRoomCount(count);
    }
  };

  // Handle adding new rooms
const handleAddRoom = async () => {
  const roomData: Room = {
    PropertyID: editedProperty.PropertyID,
    Price: editedProperty.Price,
  };

  // Validate newRoomCount to ensure it's a positive integer
  if (newRoomCount <= 0 || !Number.isInteger(newRoomCount)) {
    toast.warning(t('invalid'));
    return;
  }

  // Array to store promises for each room creation
  const roomCreationPromises = [];

  // Loop to create rooms based on newRoomCount
  for (let i = 0; i < newRoomCount; i++) {
    roomCreationPromises.push(
      api
        .createRoom(roomData)
        .catch((error) => {
          toast.warning(error.message);
        })
    );
  }

  try {
    // Execute all promises and wait for all rooms to be created
    await Promise.all(roomCreationPromises);

    // Only toast success after all rooms are created
    toast.success(t('updated'));

    await deleteKeyStore('properties');
    await deleteKeyStore(`rooms_${property.PropertyID}`);

    navigate('/property');
  } catch (error) {
    const errorMessage = errorMessages.getErrorMessage(
      // @ts-ignore
      error.statusCode,
      // @ts-ignore
      error.context
    );

    toast.error(errorMessage);
  }
};

  // Handle cancel adding rooms
  const handleRoomCancel = () => {
    setRoomEditable(false);
  };

  // Loading state when property is not available
  if (!property) {
    return <div>Loading property details...</div>;
  }

  const isHostel = editedProperty.PropertyType === 'Hostel'; // Check if property type is hostel

  // Render property details and room information
  return (
    <div className="overview-container">
      <main className="property-details-content">
        <Container maxWidth="md">
          <Typography variant="h5" gutterBottom>
            {t('propertyInformation')}
          </Typography>
          <div
            style={{
              border: '1px solid #ccc',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              background: 'transparent',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {/* Property information */}
                <Box mb={2}>
                  <Typography>
                    <strong>{t('propertyName')}:</strong>{' '}
                    {propertyEditable ? (
                      <TextField
                        name="PropertyName"
                        label="Property Name"
                        fullWidth
                        value={editedProperty.PropertyName}
                        onChange={(e) =>
                          setEditedProperty({
                            ...editedProperty,
                            PropertyName: e.target.value,
                          })
                        }
                      />
                    ) : (
                      property.PropertyName
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>
                    <strong>{t('price')}:</strong>{' '}
                    {propertyEditable ? (
                      <TextField
                        name="Price"
                        label="Price"
                        fullWidth
                        value={editedProperty.Price}
                        onChange={(e) =>
                          setEditedProperty({
                            ...editedProperty,
                            Price: e.target.value,
                          })
                        }
                      />
                    ) : (
                      `${currencySymbol}${property.Price.toLocaleString()}`
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>
                    <strong> {t('numberOfUnits')}:</strong>{' '}
                    {property.NumberOfUnits}
                  </Typography>
                </Box>
                {!isHostel && (
                  <>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('numberOfBathrooms')}:</strong>{' '}
                        {property.NumberOfBathrooms}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('numberOfBedrooms')}:</strong>{' '}
                        {property.NumberOfBedrooms}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box mb={2}>
                  <Typography>
                    <strong>{t('location')}:</strong> {property.Location}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Button group */}
                <div className="button-group">
                  {propertyEditable && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                      fullWidth
                      style={{
                        marginBottom: '10px',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {t('cancel')}
                    </Button>
                  )}
                  {!propertyEditable && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('/property')}
                      fullWidth
                      style={{
                        marginBottom: '10px',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {t('back')}
                    </Button>
                  )}

                  {user?.UserRoleType !== 'Caretaker' && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          propertyEditable ? handleUpdate : handleEditProperty
                        }
                        fullWidth
                        style={{
                          marginBottom: '10px',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {t(propertyEditable ? 'update' : 'edit')}
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpenDeleteModal(true)}
                        fullWidth
                        style={{
                          marginBottom: '10px',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {t('delete')}
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Room information */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              {t('roomInformation')}
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('total_rooms')}</TableCell>
                    <TableCell>{t('occupied_rooms')}</TableCell>
                    <TableCell>{t('available_rooms')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {/* @ts-ignore */}
                      {useRoomStore.getState().rooms?.totalRoomsCount}
                    </TableCell>
                    <TableCell>
                      {/* @ts-ignore */}
                      {useRoomStore.getState().rooms?.occupiedRoomsCount}
                    </TableCell>
                    <TableCell>
                      {/* @ts-ignore */}
                      {useRoomStore.getState().rooms?.availableRoomsCount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={2}>
              {!roomEditable && user?.UserRoleType !== 'Caretaker' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setRoomEditable(true)}
                  style={{ marginRight: '10px' }}
                >
                  {t('add_room')}
                </Button>
              )}
              {roomEditable && (
                <Typography>
                  {t('add_rooms')}
                  <TextField
                    type="number"
                    value={newRoomCount}
                    onChange={handleRoomCountChange}
                    style={{ marginLeft: '10px', width: '100px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRoom}
                    style={{ marginLeft: '10px' }}
                  >
                    {t('add')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRoomCancel}
                    style={{ marginLeft: '10px' }}
                  >
                    {t('cancel')}
                  </Button>
                </Typography>
              )}
            </Box>
          </Box>
        </Container>
      </main>

      {/* Modal for delete confirmation */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDeleteModal}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh', // Center vertically
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                maxWidth: '400px',
                width: '100%',
                textAlign: 'center',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
              }}
            >
              <Typography variant="h6" gutterBottom>
                {t('property_delete_confirmation')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDelete}
                style={{ marginRight: '10px' }}
              >
                {t('yes')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t('no')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default PropertyDetails;
