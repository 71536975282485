export function reverseDate(dateString: string): string {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  const adjustedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );

  const day = adjustedDate.getDate().toString().padStart(2, '0');
  const month = (adjustedDate.getMonth() + 1).toString().padStart(2, '0');
  const year = adjustedDate.getFullYear();

  return `${day}/${month}/${year}`;
}

export const calculateStatus = (EndDate: string): string => {
  const endDateTime = new Date(EndDate);
  const currentDateTime = new Date();

  // Set timezone to Africa/Lagos (Nigeria timezone)
  const nigeriaTimeZone = 'Africa/Lagos';

  // Convert EndDate and current time to Nigeria timezone
  endDateTime.toLocaleString('en-US', { timeZone: nigeriaTimeZone });
  currentDateTime.toLocaleString('en-US', { timeZone: nigeriaTimeZone });

  // Set times to start of day to compare only dates
  endDateTime.setHours(0, 0, 0, 0);
  currentDateTime.setHours(0, 0, 0, 0);

  // Check if the end date is in the future
  if (endDateTime > currentDateTime) {
    return 'Active';
  }
  // Check if the end date is today
  else if (endDateTime.getTime() === currentDateTime.getTime()) {
    return 'Due';
  }
  // Check if the end date is in the past
  else if (endDateTime < currentDateTime) {
    return 'Past Due';
  }
  // Default case (though theoretically should never happen)
  else {
    return 'Unknown';
  }
};

export function formatDateForInput(dateString: string) {
  if (!dateString) {
    return '';
  }

  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Extract the year, month, and day in the local time zone
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth is zero-based
  const day = date.getDate().toString().padStart(2, '0');

  // Return the date formatted as YYYY-MM-DD for the input field
  return `${year}-${month}-${day}`;
}

export function formatDate(dateString: string | Date): string {
  if (!dateString) {
    return '';
  }

  // Handle both string and Date object input
  const date =
    typeof dateString === 'string' ? new Date(dateString) : dateString;

  if (isNaN(date.getTime())) {
    return ''; // Return empty string if the date is invalid
  }

  // Extract day, month, and year in UTC to avoid timezone offset issues
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  // Format day and month to ensure 2 digits
  const dayFormatted = day < 10 ? `0${day}` : `${day}`;
  const monthFormatted = month < 10 ? `0${month}` : `${month}`;

  return `${dayFormatted}-${monthFormatted}-${year}`;
}
