import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { api } from '../utils/api';
import { Tenant as TenantProps } from '../utils/interfaces';
import { toast } from 'react-toastify';
import { reverseDate } from '../utils/dateUtils';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ExampleTemplate from './ExampleTemplate';
import { validateEmail } from '../helper/validation';
import {
  Button,
  Typography,
  Grid,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  Box,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '../stores/UserStore';
import { usePropertyStore } from '../stores/PropertyStore';
import { useTenantStore } from '../stores/TenantStore';
import { deleteKeyStore } from '../utils/indexedDB';

function Tenant() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, loadUserFromIndexedDB } = useUserStore();
  const { properties, loadPropertiesFromIndexedDB } = usePropertyStore();
  const { tenants, loadTenantsFromIndexedDB } = useTenantStore();
  const [formData, setFormData] = useState<TenantProps>({
    PropertyID: '',
    PropertyName: '',
    FirstName: '',
    LastName: '',
    RoomID: 0,
    Unit: '',
    Complex: '',
    Email: '',
    Telephone: '',
    StartDate: '',
    EndDate: '',
    file: null,
  });
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [filters, setFilters] = useState({
    Status: '',
    LastName: '',
    StartDate: '',
    EndDate: '',
    PropertyName: '',
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch user details from IndexedDB or API
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await loadUserFromIndexedDB();
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchUser();
  }, [loadUserFromIndexedDB]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        await loadPropertiesFromIndexedDB();
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchProperties();
  }, [loadPropertiesFromIndexedDB]);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        await loadTenantsFromIndexedDB();
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        toast.error(error.message);
      }
    };

    fetchTenants();
  }, [loadTenantsFromIndexedDB]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileSizeLimit = 50 * 1024 * 1024; // 50 MB
    const file = event.target.files?.[0];

    if (file) {
      // Check if the file size exceeds the limit
      if (file.size > fileSizeLimit) {
        toast.error('File size exceeds the 50MB limit.');
        return;
      }

      //@ts-ignore
      setSelectedFileName(file.name);
      setFormData({ ...formData, file: file });
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const selectedProperty = properties?.find(
      (property) => property.PropertyName === formData.PropertyName
    );

    const PropertyID = selectedProperty ? selectedProperty.PropertyID : '';

    const tenantData = {
      ...formData,
      PropertyID: PropertyID,
      file: formData.file,
    };

    const { FirstName, LastName, Unit, Email, Telephone, StartDate, EndDate } =
      tenantData;

    if (Email && !validateEmail(Email)) {
      toast.error(t('invalid_email_password'));
      return;
    }

    if (
      !PropertyID ||
      !FirstName ||
      !LastName ||
      !Unit ||
      !Email ||
      !StartDate ||
      !EndDate
    ) {
      toast.error('All fields are required');
      return;
    }

    const startDate = new Date(StartDate);
    const endDate = new Date(EndDate);

    // Check if dates are valid
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      toast.error('Invalid date input');
      return;
    }

    if (endDate < startDate) {
      toast.error('End Date cannot be earlier than Start Date');
      return;
    }

    // Convert dates to UTC format
    const utcStartDate = new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate()
      )
    );
    const utcEndDate = new Date(
      Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate()
      )
    );

    const correctedTenantData = {
      ...tenantData,
      StartDate: utcStartDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
      EndDate: utcEndDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
    };

    api
      .createTenant(correctedTenantData)
      .then(async (response) => {
        await deleteKeyStore('tenants');
        await deleteKeyStore('properties');
        toast.success(
          `${FirstName} ${LastName} ${t(response.message)} ${t('to')} ${formData.PropertyName}`
        );
        setFormData({
          PropertyID: '',
          PropertyName: '',
          FirstName: '',
          LastName: '',
          RoomID: 0,
          Unit: '',
          Complex: '',
          Email: '',
          Telephone: '',
          StartDate: '',
          EndDate: '',
          file: null,
        });
        setShowForm(false);

        loadTenantsFromIndexedDB();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleTenantClick = (tenant: TenantProps) => {
    navigate(`/tenant/${tenant.TenantID}`, { state: { tenant } });
  };

  const getStatusColumnClassName = (Status: string): string => {
    const lowercaseStatus = Status.toLowerCase();

    switch (lowercaseStatus) {
      case t('active').toLowerCase():
      case t('actif').toLowerCase():
        return 'status-active';
      case t('due').toLowerCase():
      case t('dû').toLowerCase():
        return 'status-due';
      case t('past due').toLowerCase():
      case t('en_retard').toLowerCase():
        return 'status-past-due';
      default:
        return '';
    }
  };

  const filteredTenants = tenants?.filter((tenant) => {
    const filterStatusLower = filters.Status
      ? t(filters.Status.toLowerCase())
      : '';

    //@ts-ignore
    const translatedStatus = t(tenant.Status.toLowerCase());

    // Compare translated status with filter status
    const matchesStatus = filters.Status
      ? translatedStatus.toLowerCase() === filterStatusLower.toLowerCase()
      : true;

    const matchesLastName = filters.LastName
      ? tenant.LastName.toLowerCase().includes(filters.LastName.toLowerCase())
      : true;

    const matchesStartDate = filters.StartDate
      ? new Date(tenant.StartDate) >= new Date(filters.StartDate)
      : true;

    const matchesEndDate = filters.EndDate
      ? new Date(tenant.EndDate) <= new Date(filters.EndDate)
      : true;

    const matchesPropertyName = filters.PropertyName
      ? tenant.PropertyName === filters.PropertyName
      : true;

    return (
      matchesStatus &&
      matchesLastName &&
      matchesStartDate &&
      matchesEndDate &&
      matchesPropertyName
    );
  });

  const handleClearAll = () => {
    setFormData({
      PropertyID: '',
      PropertyName: '',
      FirstName: '',
      LastName: '',
      RoomID: 0,
      Unit: '',
      Complex: '',
      Email: '',
      Telephone: '',
      StartDate: '',
      EndDate: '',
      file: null,
    });

    setFilters({
      Status: '',
      LastName: '',
      StartDate: '',
      EndDate: '',
      PropertyName: '',
    });
  };

  const handleCsvFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.info(t('nofile'));
      return;
    }

    setLoading(true);
    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

    const processFile = async (rows: TenantProps[]) => {
      const processedUnits: Set<string> = new Set();

      // Enforce limits based on subscription type
      let limit = 10;
      if (user?.SubscriptionType === 'Basic') {
        limit = 50;
      } else if (user?.SubscriptionType === 'Standard') {
        limit = 200;
      } else if (user?.SubscriptionType === 'Premium') {
        limit = rows.length; // No limit
      }

      for (let index = 0; index < rows.length; index++) {
        if (index >= limit) {
          toast.warning(
            `Allowed processing limit reached for ${user?.SubscriptionType} subscription.`
          );
          break;
        }

        const row = rows[index];

        //@ts-ignore
        if (processedUnits.has(row.Unit)) {
          toast.error(
            `Duplicate Unit ID '${row.Unit}' found in row ${index + 2}.`
          );
          continue; // Skip this entry
        }

        //@ts-ignore
        processedUnits.add(row.Unit);

        const rowWithDefaults: TenantProps = {
          ...row,
          PropertyID: row.PropertyID || 'noGUIDSupplied',
          file: null,
        };

        try {
          await api.createTenant(rowWithDefaults);
        } catch (error) {
          //@ts-ignore
          toast.error(error.message);
        }
      }

      // After processing all tenants
      await deleteKeyStore('tenants');
      await deleteKeyStore('properties');
      await loadTenantsFromIndexedDB();
    };

    const readAndProcessFile = async () => {
      try {
        let parsedData: TenantProps[] = [];

        if (fileExtension === 'csv') {
          const reader = new FileReader();
          reader.onload = async (event: ProgressEvent<FileReader>) => {
            const csvText = event.target?.result as string;
            parsedData = parseCSV(csvText);
            await processFile(parsedData);
          };
          reader.readAsText(selectedFile);
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
          const reader = new FileReader();
          reader.onload = async (event: ProgressEvent<FileReader>) => {
            const data = new Uint8Array(event.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const parsedData = XLSX.utils.sheet_to_json(firstSheet, {
              header: 1,
            });
            const formattedData = (parsedData as any[][])
              .slice(1)
              .filter((row) => row.some((cell) => cell?.trim())) // Ignore completely empty rows
              .map((row) => ({
                PropertyID: row[0] || 'defaultGUID',
                FirstName: row[1],
                LastName: row[2],
                Unit: row[4],
                Complex: row[5],
                Email: row[6],
                Telephone: row[7],
                StartDate: row[8],
                EndDate: row[9],
                file: null,
              }));
            await processFile(formattedData);
          };
          reader.readAsArrayBuffer(selectedFile);
        } else {
          toast.warning('Please upload a valid CSV or Excel file.');
        }
      } catch (error) {
        toast.error('Error processing file: ' + error);
      } finally {
        setLoading(false);
      }
    };

    await readAndProcessFile();
    toast.success(t('success'));
  };

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const parseCSV = (csvText: string) => {
    const lines = csvText.split('\n').filter((line) => line.trim() !== ''); // Filter out empty lines
    const parsedData: TenantProps[] = [];

    try {
      lines.slice(1).forEach((line, index) => {
        const values = line.split(',');

        // Validate and parse each field
        const rowData: Partial<TenantProps> = {
          PropertyID: values[0]?.trim() || '',
          FirstName: values[1]?.trim() || '',
          LastName: values[2]?.trim() || '',
          Unit: values[3]?.trim() || '',
          Complex: values[4]?.trim() || '',
          Email: values[5]?.trim() || '',
          Telephone: values[6]?.trim() || '',
          StartDate: values[7]?.trim() || '',
          EndDate: values[8]?.trim() || '',
        };

        // Check for required fields
        if (
          !rowData.PropertyID ||
          !rowData.FirstName ||
          !rowData.LastName ||
          !rowData.Unit ||
          !rowData.StartDate ||
          !rowData.EndDate
        ) {
          toast.error(
            `Missing required fields in row ${index + 2}. Cancelling parsing.`
          );
          throw new Error(
            `Missing required fields in row ${index + 2}. Cancelling parsing.`
          );
        }

        //@ts-ignore
        rowData.id = index + 1; // Assign unique ID

        parsedData.push(rowData as TenantProps);
      });

      return parsedData;
    } catch (error) {
      toast.error('Failed reading csv file: ' + error);
      return []; // Return an empty array or handle as needed
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false); // Close modal on Close Modal button click
    document.getElementById('upload-csv')?.click(); // Simulate click on file input to open file dialog
  };

  const currentRows = filteredTenants?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage); // Step 3: Handle page change
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Step 4: Handle rows per page change
  };

  // Render the component UI
  return (
    <div className="overview-container">
      <main className="tenant-content">
        {/* Filter and clear filter button */}
        <div
          className="filter-container"
          style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Grid
            container
            spacing={2}
            className="mb-3"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Grid container spacing={2} alignItems="center">
                {/* Filter inputs */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>{t('status')}</InputLabel>
                    <Select
                      name="Status"
                      value={filters.Status}
                      //@ts-ignore
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">{t('all')}</MenuItem>
                      <MenuItem value="Active">{t('active')}</MenuItem>
                      <MenuItem value="Due">{t('due')}</MenuItem>
                      <MenuItem value="Past Due">{t('past due')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="LastName"
                    value={filters.LastName}
                    //@ts-ignore
                    onChange={handleFilterChange}
                    label={t('last_name')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="date"
                    name="StartDate"
                    value={filters.StartDate}
                    //@ts-ignore
                    onChange={handleFilterChange}
                    label={t('start_date')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="date"
                    name="EndDate"
                    value={filters.EndDate}
                    //@ts-ignore
                    onChange={handleFilterChange}
                    label={t('end_date')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>{t('property')}</InputLabel>
                    <Select
                      name="PropertyName"
                      value={filters.PropertyName}
                      //@ts-ignore
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">{t('all')}</MenuItem>
                      {properties?.map((property) => (
                        <MenuItem
                          key={property.PropertyID}
                          value={property.PropertyName}
                        >
                          {property.PropertyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClearAll}
                  >
                    {t('clearFields')}
                  </Button>
                </Grid>
                <Grid item>
                  {/* Conditionally render the Button */}
                  {user?.SubscriptionType !== 'Free' && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openModal}
                    >
                      {t('upload_csv')}
                    </Button>
                  )}
                </Grid>

                <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'white',
                      padding: '20px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <ExampleTemplate closeModal={closeModal} />
                  </div>
                </Modal>

                <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="upload-csv"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleCsvFileChange}
                />

                <Grid item>
                  {/* Conditionally render the Button */}
                  {user?.UserRoleType !== 'Caretaker' && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-0"
                      onClick={toggleForm}
                      style={{ marginLeft: '10px' }}
                    >
                      {t('add_new')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* Table displaying tenants */}
        <div className="tenant-table-container" style={{ overflowY: 'auto' }}>
          {loading ? (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <>
              <Table
                className={`table table-bordered ${showForm ? 'blur-background' : ''}`}
              >
                <TableHead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: '#d5deee',
                  }}
                >
                  <TableRow>
                    <TableCell>{t('first_name')}</TableCell>
                    <TableCell>{t('last_name')}</TableCell>
                    <TableCell>{t('unit')}</TableCell>
                    <TableCell>{t('complex')}</TableCell>
                    <TableCell>{t('start_date')}</TableCell>
                    <TableCell>{t('end_date')}</TableCell>
                    <TableCell>{t('property')}</TableCell>
                    <TableCell>{t('status')}</TableCell>
                  </TableRow>
                </TableHead>

                {/* Table body showing filtered tenants */}
                <TableBody>
                  {currentRows?.map((tenant) => (
                    <TableRow
                      key={tenant.TenantID}
                      onClick={() => handleTenantClick(tenant)}
                      className="table-row"
                    >
                      <TableCell>{tenant.FirstName}</TableCell>
                      <TableCell>{tenant.LastName}</TableCell>
                      <TableCell>{tenant.Unit}</TableCell>
                      <TableCell>{tenant.Complex}</TableCell>
                      <TableCell>{reverseDate(tenant.StartDate)}</TableCell>
                      <TableCell>{reverseDate(tenant.EndDate)}</TableCell>
                      <TableCell>{tenant.PropertyName}</TableCell>
                      <TableCell
                        // @ts-ignore
                        className={getStatusColumnClassName(tenant.Status)}
                      >
                        {/* @ts-ignore */}
                        {t(tenant.Status)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* Pagination controls */}
              <div
                style={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fff',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '4px', // Reduced padding to decrease height
                  zIndex: 1000,
                }}
              >
                <Typography variant="body2">
                  {t('page_of', {
                    page: page + 1,
                    totalPages: Math.ceil(
                      (filteredTenants?.length ?? 0) / rowsPerPage
                    ),
                  })}
                </Typography>
                <TablePagination
                  component="div"
                  count={filteredTenants?.length ?? 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  sx={{ '.MuiTablePagination-toolbar': { minHeight: '36px' } }} // Reduced height of pagination toolbar
                />
              </div>
            </>
          )}
        </div>

        <Modal open={showForm} onClose={toggleForm}>
          <div className="modal-content">
            <form className="mt-3" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>{t('property')}</InputLabel>
                    <Select
                      name="PropertyName"
                      value={formData.PropertyName}
                      //@ts-ignore
                      onChange={handleInputChange}
                      required
                    >
                      {properties?.map((property) => (
                        <MenuItem
                          key={property.PropertyID}
                          value={property.PropertyName}
                        >
                          {property.PropertyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="FirstName"
                    value={formData.FirstName}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('first_name')}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="LastName"
                    value={formData.LastName}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('last_name')}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="Unit"
                    value={formData.Unit}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('unit')}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="Complex"
                    value={formData.Complex}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('complex')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="email"
                    name="Email"
                    value={formData.Email}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('email')}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="Telephone"
                    value={formData.Telephone}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('telephone')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      marginBottom: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ marginRight: '10px' }}
                    ></Typography>
                    <Button
                      variant="outlined"
                      component="label"
                      htmlFor="upload-contract-file"
                      style={{
                        padding: '10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      {selectedFileName
                        ? t('uploaded_file', { fileName: selectedFileName })
                        : t('upload_contract_file')}
                      <input
                        id="upload-contract-file"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    name="StartDate"
                    value={formData.StartDate}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('start_date')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="date"
                    name="EndDate"
                    value={formData.EndDate}
                    //@ts-ignore
                    onChange={handleInputChange}
                    label={t('end_date')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '10px' }}
                  >
                    {t('add_tenant')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={toggleForm}
                  >
                    {t('cancel')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        {/* Spinner component */}
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
            }}
          >
            <Spinner />
          </Box>
        )}
      </main>
    </div>
  );
}

export default Tenant;
