export const plans = [
  {
    name: 'Basic',
    price: '$4.99',
    monthly: 'Monthly',
    features: [
      'Allowed Properties: 3',
      'Allowed Tenants: 10',
      'Analysis View: No',
    ],
    styleClass: 'basic',
  },
  {
    name: 'Standard',
    price: '$8.99',
    monthly: 'Monthly',
    features: [
      'Allowed Properties: Unlimited',
      'Allowed Tenants: 10',
      'Analysis View: No',
    ],
    styleClass: 'standard',
  },
  {
    name: 'Premium',
    price: '$12.99',
    monthly: 'Monthly',
    features: [
      'Allowed Properties: Unlimited',
      'Allowed Tenants: Unlimited',
      'Analysis View: Yes',
    ],
    styleClass: 'premium',
  },
];
