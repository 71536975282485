import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Button
        onClick={handleOpen}
        variant="outlined"
        sx={{ color: '#000', borderColor: '#000' }}
      >
        {t('privacy_policy')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="privacy-policy-modal-title"
        aria-describedby="privacy-policy-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: '#fff',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            maxHeight: '40vh',
            overflowY: 'auto',
          }}
        >
          <Typography variant="h5" id="privacy-policy-modal-title" gutterBottom>
            {t('privacy_policy')}
          </Typography>
          <Typography id="privacy-policy-modal-description" sx={{ mt: 2 }}>
            <p>
              <strong>{t('privacy_policy_section_1_title')}</strong>{' '}
              {t('privacy_policy_section_1_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_2_title')}</strong>{' '}
              {t('privacy_policy_section_2_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_3_title')}</strong>{' '}
              {t('privacy_policy_section_3_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_4_title')}</strong>{' '}
              {t('privacy_policy_section_4_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_5_title')}</strong>{' '}
              {t('privacy_policy_section_5_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_6_title')}</strong>{' '}
              {t('privacy_policy_section_6_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_7_title')}</strong>{' '}
              {t('privacy_policy_section_7_description')}
            </p>
            <p>
              <strong>{t('privacy_policy_section_8_title')}</strong>{' '}
              {t('privacy_policy_section_8_description')}
              <a href="https://paystack.com/terms">
                {t('paystack_privacy_policy')}
              </a>
              .
            </p>
          </Typography>
          <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default PrivacyPolicyModal;
