import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Plan, Subscription } from '../utils/interfaces';
import { api } from '../utils/api';
import useScheduledConversionRateUpdate from '../helper/useScheduledConversionRateUpdate';
import { useConversionRateStore } from '../stores/ConversionRateStore';
import { toast } from 'react-toastify';
import { formatDate } from '../utils/dateUtils';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import { useUserStore } from '../stores/UserStore';
import { deleteKeyStore } from '../utils/indexedDB';
import { PaystackButton } from 'react-paystack';
import { plans } from '../helper/plans';

interface FormData {
  SubscriptionType: string;
  Duration: string;
  PromoCode: string;
}

function Plantype() {
  const { t } = useTranslation();
  const { user, loadUserFromIndexedDB } = useUserStore();
  const { conversionRate, fetchConversionRate } = useConversionRateStore();
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [formData, setFormData] = useState<FormData>({
    SubscriptionType: '',
    Duration: '3 months',
    PromoCode: '',
  });
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [showSubscriptions, setShowSubscriptions] = useState(true);
  const [usePromoCode, setUsePromoCode] = useState(false);
  const [isPlanSelected, setIsPlanSelected] = useState(false);

  useScheduledConversionRateUpdate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await loadUserFromIndexedDB();
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    fetchUser();
  }, [loadUserFromIndexedDB]);

  useEffect(() => {
    const fetchConversionRateData = async () => {
      if (user?.Country) {
        try {
          await fetchConversionRate();
        } catch (error) {
          console.error('Failed to fetch conversion rate:', error);
        }
      } else {
        console.error('Country code not found for the selected user');
      }
    };

    fetchConversionRateData();
  }, [user?.Country, fetchConversionRate]);

  const handlePlanClick = (plan: Plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, SubscriptionType: plan.name });
    setShowSubscriptions(false);
    setIsPlanSelected(true);
  };

  const handleChange = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handlePromoCodeToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setUsePromoCode(event.target.checked);
  };

  const handlePaymentSuccess = async (
    response: { reference: string } | string
  ) => {
    let reference: string;

    if (typeof response === 'string') {
      reference = response;
    } else {
      reference = response.reference;
    }

    if (reference === 'promo_code_used') {
      // Promo code used, skip payment
      try {
        const subCreateData: Subscription = {
          SubscriptionType: formData.SubscriptionType,
          Duration: formData.Duration,
          PromoCode: formData.PromoCode,
          PaymentReference: 'PromoCode_' + formData.PromoCode,
        };
        await api.createSubscription(subCreateData);
        await deleteKeyStore('profileDetails');
        toast.success(t('subscription_created'));
        fetchSubscriptions();
        resetForm();
        setShowSubscriptions(true);
      } catch (error) {
        toast.error(t('subscription_creation_failed'));
      }
    } else {
      // Payment successful, call createSubscription API
      try {
        const subCreateData: Subscription = {
          SubscriptionType: formData.SubscriptionType,
          Duration: formData.Duration,
          PromoCode: formData.PromoCode,
          PaymentReference: reference,
        };
        await api.createSubscription(subCreateData);
        await deleteKeyStore('profileDetails');
        toast.success(t('subscription_created'));
        fetchSubscriptions();
        resetForm();
        setShowSubscriptions(true);
      } catch (error) {
        toast.error(t('subscription_creation_failed'));
      }
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (usePromoCode && !formData.PromoCode) {
      toast.error(t('no_promo_code'));
      return;
    }

    if (!selectedPlan) {
      toast.error(t('select_plan'));
      return;
    }

    if (usePromoCode && formData.PromoCode) {
      // Promo code used, handle subscription directly
      await handlePaymentSuccess('promo_code_used');
    } else {
      // Proceed with Paystack payment
      const paystackButton = document.querySelector(
        '.paystack-button'
      ) as HTMLButtonElement | null;
      if (paystackButton) {
        paystackButton.click();
      }
    }
  };

  const fetchSubscriptions = () => {
    api
      .getSubscription()
      .then(async (response) => {
        await deleteKeyStore('profileDetails');
        setSubscriptions(response);
      })
      .catch((error) => {
        toast.error((error as Error).message);
        setSubscriptions([]);
      });
  };

  const resetForm = () => {
    setSelectedPlan(null);
    setFormData({
      SubscriptionType: '',
      Duration: '3 months',
      PromoCode: '',
    });
    setIsPlanSelected(false);
  };

  const handleCancel = () => {
    resetForm();
    setShowSubscriptions(true);
  };

  const calculatePaystackAmount = (
    price: string,
    duration: string,
    conversionRate: string
  ): number => {
    const priceInUSD = parseFloat(price.replace(/[^0-9.]/g, ''));
    const months = parseInt(duration.split(' ')[0], 10);
    const rate = parseFloat(conversionRate);

    // Calculate amount in kobo
    const amountInKobo = priceInUSD * months * rate * 100;

    // Round to a fixed number of decimal places to handle precision issues
    const roundedAmount = Math.round(amountInKobo);

    // Return the amount rounded down to remove decimals
    return Math.floor(roundedAmount);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <div className="overview-container">
      <main className="plantype-content">
        <div className="subscription-container">
          {!isPlanSelected && showSubscriptions && (
            <div>
              <Typography variant="h1">{t('pickYourPlan')}</Typography>
              <Box className="plans">
                {plans.map((plan) => (
                  <Box
                    className={`plan ${plan.styleClass}`}
                    key={plan.name}
                    onClick={() => handlePlanClick(plan)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Box className={`plan-header ${plan.styleClass}`}>
                      <Typography variant="h2">{plan.name}</Typography>
                      <Typography variant="body1" className="price">
                        {`${plan.price} / ${plan.monthly}`}
                      </Typography>
                      {plan.name === 'Standard' && (
                        <Typography
                          variant="body2"
                          className="recommended-badge"
                        >
                          {t('recommended')}
                        </Typography>
                      )}
                    </Box>
                    <ul className="plan-features">
                      {plan.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                    <Button
                      variant="contained"
                      className={`get-started ${plan.styleClass}`}
                    >
                      {t('get_started')}
                    </Button>
                  </Box>
                ))}
              </Box>
            </div>
          )}

          {isPlanSelected && selectedPlan && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              className="file-upload-form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="h2">
                  {t('selectedPlan')}: {selectedPlan.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <FormControl sx={{ mb: 2 }}>
                  <InputLabel>{t('duration')}</InputLabel>
                  <Select
                    name="Duration"
                    value={formData.Duration}
                    onChange={handleChange}
                    sx={{ width: '300px' }}
                  >
                    <MenuItem value="3 months">{t('3_months')}</MenuItem>
                    <MenuItem value="6 months">{t('6_months')}</MenuItem>
                    <MenuItem value="12 months">{t('12_months')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={usePromoCode}
                        onChange={handlePromoCodeToggle}
                      />
                    }
                    label={t('use_promo_code')}
                  />
                </FormControl>
                {usePromoCode && (
                  <TextField
                    name="PromoCode"
                    label={t('promo_code')}
                    value={formData.PromoCode}
                    onChange={handleChange}
                    sx={{ width: '300px', mt: 2 }}
                  />
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                {usePromoCode ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2 }}
                  >
                    {t('apply_now')}
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                    >
                      {t('pay_now')}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      {t('cancel')}
                    </Button>
                  </>
                )}
              </Box>

              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <PaystackButton
                  email={user?.Email || ''}
                  amount={calculatePaystackAmount(
                    selectedPlan.price,
                    formData.Duration,
                    conversionRate
                  )}
                  currency="NGN" // Set currency to NGN since that's the only supported currency
                  publicKey={process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || ''} // Access public key from environment variables
                  onSuccess={handlePaymentSuccess}
                  onClose={() => toast.error(t('payment_not_completed'))}
                  className="paystack-button"
                />
              </Box>
            </Box>
          )}

          {!isPlanSelected && showSubscriptions && subscriptions && (
            <Box className="subscription-table">
              {subscriptions.some((sub) => sub.SubscriptionType !== 'Free') && (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>{t('current_subscription')}</th>
                        <th>{t('valid_till')}</th>
                        <th>{t('status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map(
                        (sub) =>
                          sub.SubscriptionType !== 'Free' && (
                            <tr key={sub.SubscriptionID}>
                              <td>{t(sub.SubscriptionType)}</td>
                              <td>
                                {sub.EndDate ? formatDate(sub.EndDate) : 'N/A'}
                              </td>

                              <td>
                                {sub.IsActive ? t('active') : t('not_active')}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </Box>
          )}
        </div>
      </main>
    </div>
  );
}

export default Plantype;
